import React from 'react';
import { Link } from 'gatsby';

import App from 'components/App';
import Image from 'components/Image';
import SEO from 'components/Seo';

import LaunchingSoon from 'components/LaunchingSoon';

const IndexPage = () => (
  <App>
    <SEO title="Launching Soon" />

    <LaunchingSoon />
  </App>
);

export default IndexPage;
